import React, { useEffect, useState } from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import { requiredValidator } from "../validators";

import { AppContext } from "../AppContext";
import { useStores } from "src/stores";
import { useServices } from "src/services";
import { Input } from "src/components/form/Input";
import cpHeaderLogo from "../assets/images/CarePenguin_header_logo.png";

const SignUpScreen = () => {
  const { ...formValues } = React.useContext(AppContext);
  const localizationService = useLocalization();
  const history = useHistory();

  const [signUpError, setSignUpError] = useState<string>();
  const { signUp, login } = useStores();
  const { carePenguin } = useServices();

  // let location = useLocation<Location>();

  useEffect(() => {
    if (login.loginObject) {
      console.log("[LoginScreen]", "I think we are already logged in");
      carePenguin.getAccount().then(() => {
        history.replace("/");
      });
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = React.useCallback(
    async (dataItem) => {
      const call: any = await carePenguin.doSignUpVerify(
        signUp.signUpEmail,
        dataItem.code
      );
      if (call.result.errors) {
        console.log("[SignUpScreen]", "sign up verify Error");
        setSignUpError(call.result.errors.join("\n"));
        return;
      }
      if (call.result.user) {
        console.log("[SignUpScreen]", "success");
        login.setLoginObject(call.result as Login);
        await carePenguin.getAccount();
      }
      history.replace("/login");
    },
    [carePenguin, signUp.signUpEmail, history, login]
  );

  return (
    <div id="Login" className="login-page">
      <div className="card-container" style={{ maxWidth: 700 }}>
        <div className="card-component">
          <div className="header-img-container">
            <img
              src={cpHeaderLogo}
              alt="carepenguin header logo"
              className="cp-header-img"
            />
          </div>
          <Button onClick={() => history.push("/signUp")} className="back-btn">
            <span className="k-icon k-i-arrow-chevron-left"></span>Back
          </Button>
          <p className="reset-text">
            Check your email for the code sent to you and enter it below. You
            will then be redirected to the login page. If you did not receive an
            email, please check your spam folder or contact support.
          </p>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              ...formValues,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                {signUpError && <Error>{signUpError}</Error>}
                <Field
                  id={"code"}
                  name={"code"}
                  label={localizationService.toLanguageString(
                    "custom.verificationCode",
                    "Verification Code"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <hr />
                <div className={"k-form-buttons"}>
                  <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                    style={{ fontFamily: "Inter-Medium" }}
                  >
                    {localizationService.toLanguageString(
                      "custom.submit",
                      "Submit"
                    )}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default SignUpScreen;
