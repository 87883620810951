import { observable, action, makeObservable } from 'mobx';
import { persist } from 'mobx-persist';

import { HydratedStore } from 'src/utils/classes';

class signUpStore extends HydratedStore {
  constructor() {
    super('signUpStore'); // Storage ID

    makeObservable(this);
  }

  @persist @observable signUpEmail: string = '';
  @observable signUpPassword: string = '';
  @observable signUpPhone: string = '';
  @observable signUpName: string = '';

  @action setSignUpEmail = (value: string) => this.signUpEmail = value;
  @action setSignUpPassword = (value: string) => this.signUpPassword = value;
  @action setSignUpPhone = (value: string) => this.signUpPhone = value;
  @action setSignUpName = (value: string) => this.signUpName = value;

}

export default new signUpStore();
