/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
// import { toJS } from "mobx";
import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "../components/form/Input";
import { AppContext } from "../AppContext";
import { useStores } from "../stores";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { requiredValidator, emailValidator } from "../validators";
import carePenguin from "src/services/carePenguin";
import { Loader } from "@progress/kendo-react-indicators";

const Profile = () => {
  const { languageId, onLanguageChange, onProfileChange, ...formValues } =
    React.useContext(AppContext);
  const localizationService = useLocalization();
  const history = useHistory();
  const { login, ui } = useStores();
  const [editError, setEditError] = useState<string>("");
  const [chargbeeError, setChargebeeError] = useState<string>("");
  const [changePassError, setChangePassError] = useState<string>("");
  const [notOwnerError, setNotOwnerError] = useState<string>("");
  const [editSuccess, setEditSuccess] = useState<string>("");
  const [changePassSuccess, setChangePassSuccess] = useState<string>("");
  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);
  const [downgradeConfirmVisible, setDowngradeConfirmVisible] =
    useState<boolean>(false);
  const [changePasswordVisible, setChangePasswordVisible] =
    useState<boolean>(false);
  const [isAccountOwner, setIsAccountOwner] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<SelectedAccount>();
  const [accountList, setAccountList] = useState<Array<any>>();

  useEffect(() => {
    if (!login.loginObject) {
      console.log("[not logged in");
      carePenguin.doLogout();
      return;
    }
    if (!ui.account) {
      ui.setIsGettingAccount(true);
    } else {
      ui.setIsGettingAccount(false);
    }
    const userId = login.loginObject?.user?.id;
    const accountHolderId = ui.account?.accountOwnerUserId;
    // const isPremium =
    //   (ui.account as Account).accountBillingOverview === "PAID" &&
    //   (ui.account as Account).accountId !== 23;
    // ui.setIsPremiumAccount(isPremium);
    if (userId === accountHolderId) {
      setIsAccountOwner(true);
    } else {
      setIsAccountOwner(false);
    }
    // display dropdown (on smaller screens where it wont fit on header)
    let localAccount;
    // we simplify the account objects because DropDownList wants the full defaultValue regardless of what the dataItemKey is
    const localAccountList = login.loginObject.accounts.map((account) => {
      if (account.id.toString() === login.selectedAccountID.toString()) {
        localAccount = { name: account.name, id: `${account.id}` };
      }
      return { name: account.name, id: `${account.id}` };
    });
    setSelectedAccount(localAccount);
    setAccountList(localAccountList);

    setEditError("");
    setEditSuccess("");
    setChangePassError("");
    setChangePassSuccess("");
    setDeleteConfirmVisible(false);
    // eslint-disable-next-line
  }, []);

  // const showDeleteUser = () => {
  //   setDeleteConfirmVisible(true);
  // };

  const deleteUser = async () => {
    const call: any = await carePenguin.deleteUser(login.loginObject?.user?.id);
    if (call.result.errors) {
      console.log("[ProfileScreen]", "delete user Error");
      setEditError(call.result.errors.join("\n"));
      setDeleteConfirmVisible(false);
      return;
    }
    setDeleteConfirmVisible(false);
    return carePenguin.doLogout();
  };

  const showDowngradeSub = () => {
    if (!isAccountOwner) {
      setNotOwnerError("Only account owners can access this feature.");
      return;
    }
    setDowngradeConfirmVisible(true);
  };

  const doDowngradeSubscription = async () => {
    const call: any = await carePenguin.cancelSubscription(
      (ui.account as Account).accountId
    );
    if (call.result.errors) {
      console.log("[ProfileScreen]", "downgrade subscription Error");
      setEditError(call.result.errors.join("\n"));
      setDowngradeConfirmVisible(false);
      return;
    }
    setDowngradeConfirmVisible(false);
    setEditError(
      "CarePenguin Premium successfully cancelled. This page will refresh momentarily."
    );
    setTimeout(() => {
      setEditError("");
      window.location.reload();
    }, 5000);
  };

  const onSubmit = React.useCallback(
    async (dataItem) => {
      const userID = login.loginObject?.user?.id;
      const call: any = await carePenguin.doEditAccountInfo(
        userID,
        dataItem.email,
        dataItem.name
      );
      if (call.result.errors) {
        console.log("[ProfileScreen]", "Edit profile Error");
        setEditError(call.result.errors.join("\n"));
        return;
      }
      if (call.result) {
        const call: any = await carePenguin.getUser(userID);
        if (call.result.errors) {
          console.log("[ProfileScreen]", "Edit profile get user Error");
          setEditError(call.result.errors.join("\n"));
          return;
        }
        let loginCopy = login.loginObject as Login;
        loginCopy.user = call.result;
        login.setLoginObject(loginCopy as Login);
        setEditError("");
        setEditSuccess("Changes saved successfully.");
        setTimeout(() => {
          setEditSuccess("");
        }, 3000);
        history.replace("/");
      }
    },
    [history, login]
  );

  const changePasswordSubmit = React.useCallback(async (dataItem) => {
    if (
      dataItem.confirmNewPass !== dataItem.newPassword ||
      dataItem.confirmNewPass.length < 5
    ) {
      setChangePassError(
        "New passwords must match and be at least 5 characters long"
      );
      return;
    }
    const call: any = await carePenguin.changePassword(
      dataItem.currentPassword,
      dataItem.newPassword
    );
    if (call.result.errors[0] === "Not logged in") {
      setChangePassError("Incorrect password.");
      return;
    }
    if (call.result.errors) {
      console.log("[ProfileScreen]", "change password Error");
      setChangePassError(call.result.errors.join("\n"));
      return;
    }
    if (call.result) {
      setChangePasswordVisible(false);
      setChangePassError("");
      setChangePassSuccess("Password changed successfully.");
      setTimeout(() => {
        setChangePassSuccess("");
      }, 3000);
    }
  }, []);

  const name = login.loginObject?.user?.name;
  const email = login.loginObject?.user?.email;
  console.log("ISGETTINGACCOUNT", ui.isGettingAccount);

  if (ui.isGettingAccount) {
    return (
      <div
        id="Profile"
        className="profile-page main-content"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader
          style={{ marginTop: "30px", color: "#4374B4" }}
          type="infinite-spinner"
        />
      </div>
    );
  }
  return (
    <div id="Profile" className="profile-page main-content">
      {deleteConfirmVisible && (
        <Dialog
          title={"Confirm Deactivation"}
          onClose={() => setDeleteConfirmVisible(false)}
          closeIcon={false}
          style={{ fontFamily: "Rubik-Medium" }}
        >
          <p
            style={{
              // margin: "25px",
              textAlign: "center",
              fontFamily: "Rubik-Regular",
            }}
          >
            Are you sure you want to deactivate your account? This is a
            permanent action.
          </p>
          <DialogActionsBar>
            <button
              className="k-button dialogue-cancel-btn"
              onClick={() => setDeleteConfirmVisible(false)}
              style={{ fontFamily: "Rubik-Regular" }}
            >
              No
            </button>
            <button
              className="k-button dialogue-confirm-btn"
              onClick={() => deleteUser()}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {downgradeConfirmVisible && (
        <Dialog
          title={"Confirm Subscription Downgrade"}
          onClose={() => setDowngradeConfirmVisible(false)}
          closeIcon={false}
          style={{
            fontFamily: "Rubik-Medium",
            width: "60%",
            marginLeft: "20%",
          }}
        >
          <p
            style={{
              // margin: "25px",
              textAlign: "center",
              fontFamily: "Rubik-Regular",
            }}
          >
            Are you sure you want to cancel your CarePenguin Premium
            subscription?
            <br />
            You won't be able to receive alerts about your loved one or invite
            family members to your account.
          </p>
          <DialogActionsBar>
            <button
              className="k-button dialogue-cancel-btn"
              onClick={() => setDowngradeConfirmVisible(false)}
            >
              No
            </button>
            <button
              className="k-button dialogue-confirm-btn"
              onClick={() => doDowngradeSubscription()}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      <div className="settings">
        {login.loginObject && (
          <DropDownList
            className="dropdown"
            data={accountList}
            textField="name"
            dataItemKey="id"
            defaultValue={selectedAccount}
            value={selectedAccount}
            onChange={async (event) => {
              console.log("changing accounts...", event.target.value.id);
              setSelectedAccount({
                name: event.target.value.name,
                id: `${event.target.value.id}`,
              });
              await carePenguin.getAccount(event.target.value.id);
              history.push("/locations");
            }}
          />
        )}
        &nbsp;
      </div>
      <div className="card-container">
        <h3 className="account-title">My Account</h3>
        <div className="card-component">
          <Form
            onSubmit={onSubmit}
            initialValues={{
              ...formValues,
              name,
              email,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                {editError && <Error>{editError}</Error>}
                <Field
                  id={"name"}
                  name={"name"}
                  label={localizationService.toLanguageString(
                    "custom.name",
                    "Name"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <Field
                  id={"email"}
                  name={"email"}
                  type={"email"}
                  placeholder={"e.g.: peter@gmail.com"}
                  label={localizationService.toLanguageString(
                    "custom.email",
                    "Email"
                  )}
                  validator={emailValidator}
                  component={Input}
                />
                <hr />
                {editSuccess && (
                  <Error style={{ color: "blue" }}>{editSuccess}</Error>
                )}
                {/* <div className="save-changes-btn-container"> */}
                <Button
                  primary={true}
                  type={"submit"}
                  className="save-changes-btn"
                  disabled={!formRenderProps.allowSubmit}
                >
                  {localizationService.toLanguageString(
                    "custom.saveChanges",
                    "Save Changes"
                  )}
                </Button>
                {/* </div> */}
              </FormElement>
            )}
          />
        </div>
        <div>
          <Button
            onClick={() => setChangePasswordVisible(!changePasswordVisible)}
            className="change-pass-btn"
          >
            {changePasswordVisible ? "Cancel" : "Change Password"}
          </Button>
        </div>
        {changePassSuccess && (
          <Error style={{ color: "blue" }}>{changePassSuccess}</Error>
        )}
        <div
          className="card-component"
          style={changePasswordVisible ? {} : { display: "none" }}
        >
          <Form
            onSubmit={changePasswordSubmit}
            initialValues={
              {
                // changePassValues,
                // ...formValues,
                // name,
                // email,
              }
            }
            render={(formRenderProps) => (
              <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                {changePassError && <Error>{changePassError}</Error>}
                <Field
                  id={"currentPassword"}
                  name={"currentPassword"}
                  label={localizationService.toLanguageString(
                    "custom.currentPassword",
                    "Current Password"
                  )}
                  validator={requiredValidator}
                  type={"password"}
                  component={Input}
                />
                {/* // TODO: disable autoFill for newPassword fields */}
                <Field
                  id={"newPassword"}
                  name={"newPassword"}
                  type={"password"}
                  label={localizationService.toLanguageString(
                    "custom.newPassword",
                    "New Password"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <Field
                  id={"confirmNewPass"}
                  name={"confirmNewPass"}
                  type={"password"}
                  label={localizationService.toLanguageString(
                    "custom.confirmNewPass",
                    "Confirm New Password"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <hr />
                {editSuccess && (
                  <Error style={{ color: "blue" }}>{editSuccess}</Error>
                )}
                <div className={"k-form-buttons"}>
                  <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                    className="set-new-pass-btn"
                  >
                    {localizationService.toLanguageString(
                      "custom.setNewPassword",
                      "Set New Password"
                    )}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
      <div className="card-container">
        <div className="caregiver-header">
          <h3 className="caregiver-title">Subscription Settings</h3>
        </div>
        <p className="subscription-status">
          Current Plan:{" "}
          {ui.isPremiumAccount
            ? "(Premium Subscription)"
            : "(Basic Subscription)"}
        </p>
        {chargbeeError && <Error>{chargbeeError}</Error>}
        {/* <Button
          onClick={async () => {
            if (!isAccountOwner) {
              setNotOwnerError("Only account owners can access this feature.");
              return;
            }
            const call: any = await carePenguin.createPortalSession(
              (ui.account as Account).accountId
            );
            if (call.errors) {
              setChargebeeError(call.errors.join("\n"));
              return;
            }
            let chargebeeInstance: any;
            chargebeeInstance = (window as any).Chargebee.init({
              site: "carepenguin",
            });
            // below sets on-close callback for this portal; does not work for upgrade portal and don't see an equivalent in the Chargebee docs
            // chargebeeInstance.setPortalCallbacks({
            //   close: () => {
            //     console.log("closed!!");
            //   },
            // });
            chargebeeInstance = (window as any).Chargebee.getInstance();
            chargebeeInstance.setPortalSession(call);
            let cbPortal = chargebeeInstance.createChargebeePortal();
            cbPortal.open();
          }}
          className="manage-sub-btn-profile"
        >
          Manage Subscription
        </Button> */}
        <Button
          onClick={async () => {
            if (!isAccountOwner) {
              setNotOwnerError("Only account owners can access this feature.");
              return;
            }
            const cbInstance = (window as any).Chargebee.getInstance();
            const cart = cbInstance.getCart();
            const product = cbInstance.initializeProduct(
              "cp-premium-service-plan",
              1
            );
            cart.replaceProduct(product);
            const customer = {
              email: login.loginObject?.user?.email.includes("+")
                ? null
                : login.loginObject?.user?.email,
              carePenguinUserId: login.loginObject?.user?.id,
            };
            cart.setCustomer(customer);
            cart.proceedToCheckout();
          }}
          className="manage-sub-btn-profile"
        >
          Upgrade Subscription
        </Button>
        <Button
          onClick={() => showDowngradeSub()}
          className="downgrade-btn"
          disabled={!ui.isPremiumAccount}
        >
          Downgrade Subscription
        </Button>
        {/* <Button onClick={() => showDeleteUser()} className="deactivate-btn">
          Deactivate Account
        </Button> */}
        {notOwnerError && <Error>{notOwnerError}</Error>}
      </div>
    </div>
  );
};

export default Profile;
