import moment from "moment-timezone";
import { observer } from "mobx-react-lite";
// import { toJS } from "mobx";
// import { useLocalization } from "@progress/kendo-react-intl";
import { useStores } from "src/stores";
import carePenguin from "src/services/carePenguin";
import { useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory, useParams } from "react-router-dom";
// import { Loader } from "@progress/kendo-react-indicators";
// import carePenguin from "src/services/carePenguin";

const DevicesScreen = () => {
  // const localizationService = useLocalization();
  const history = useHistory();
  const { location_id } = useParams<DevicesParams>();
  const { ui, login } = useStores();
  const location = ui.account?.locations?.find((location) => {
    return location.id.toString() === location_id;
  });

  useEffect(() => {
    if (!login.loginObject) {
      console.log("[not logged in");
      carePenguin.doLogout();
      return;
    }
    // console.log("location ID", location_id);
    // console.log(
    //   "LOGIN OBJ",
    //   toJS(login.loginObject),
    //   toJS(ui.account),
    //   ui.isAccountOwner
    // );
  }, [location_id, login.loginObject, ui.account, ui.isAccountOwner]);

  const renderSubHeader = (lastEventReceived: string, timezone: string) => {
    if (!lastEventReceived) {
      return (
        <p className="sensor-subheader" style={{ color: "red" }}>
          Warning: Not receiving sensor data
        </p>
      );
    } else if (
      moment(lastEventReceived)
        .tz(timezone)
        .isBefore(moment().tz(timezone).subtract(16, "minutes"))
    ) {
      return (
        <p className="sensor-subheader" style={{ color: "red" }}>
          Warning: Sensor data is stale
        </p>
      );
    }
    return <p className="sensor-subheader">Sensor data is up to date</p>;
  };

  const renderLastReceived = (lastEventReceived: string, timezone: string) => {
    const now = moment().tz(timezone);
    let stamp = "seconds";
    let difference = now.diff(
      moment(lastEventReceived).tz(timezone),
      "seconds"
    );
    if (difference > 60) {
      difference = now.diff(moment(lastEventReceived).tz(timezone), "minutes");
      stamp = "minutes";
    }
    if (!moment(lastEventReceived).isSame(moment().tz(timezone), "hour")) {
      return `at ${moment(lastEventReceived)
        .tz(timezone)
        .format("h:mm a")} on ${moment(lastEventReceived)
        .tz(timezone)
        .format("MMM Do YYYY")}`;
    }
    return `${difference} ${stamp} ago`;
  };

  const renderDevices = (sensors: Sensor[]) => {
    return sensors.map((sensor) => (
      <div key={sensor.id} className="sensor-card-container">
        <div className="sensor-card-header">
          <span
            className="k-icon k-i-circle"
            style={
              moment(sensor.lastEventReceived)
                .tz(sensor.timezone)
                .isBefore(moment().tz(sensor.timezone).subtract(10, "minutes"))
                ? { color: "#4374B4" }
                : { color: "#3f72b4" }
            }
          ></span>
          <h3 className="sensor-name">{sensor.name}</h3>
        </div>
        {renderSubHeader(sensor.lastEventReceived, sensor.timezone)}
        {!sensor.firstWaterUsageToday ? null : (
          <p className="sensor-detail-text">
            • First detected water use at{" "}
            {moment(sensor.firstWaterUsageToday)
              .tz(sensor.timezone)
              .format("h:mm a")}
            .
          </p>
        )}
        {sensor.lastWaterUsageToday ? (
          <p className="sensor-detail-text">
            • Last detected water use at{" "}
            {moment(sensor.lastWaterUsageToday)
              .tz(sensor.timezone)
              .format("h:mm a")}
            .
          </p>
        ) : null}
        <p className="sensor-detail-text">
          • {sensor.usagesToday ? sensor.usagesToday : "0"} water use event
          {sensor.usagesToday === 1 ? null : "s"} detected today.
        </p>
        <p className="sensor-detail-text">
          • Last received data{" "}
          {renderLastReceived(sensor.lastEventReceived, sensor.timezone)}.
        </p>
        <p className="sensor-detail-text">
          • Sensor timezone is {sensor.timezone}.
        </p>
      </div>
    ));
  };

  return (
    <div id="Devices" className="devices-page main-content">
      <div className="card-container">
        <Button onClick={() => history.push("/locations")} className="back-btn">
          <span className="k-icon k-i-arrow-chevron-left"></span>Back to
          Locations
        </Button>
        <h3 className="card-title">
          {`Sensors at Location: ${location?.name}`}
        </h3>
        <div className="card-component">
          {ui.account?.locations?.length ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
              }}
            >
              {location?.sensors.length ? (
                renderDevices(location.sensors)
              ) : (
                <p style={{ fontFamily: "Inter-Medium" }}>
                  There are no sensors installed at this location.
                </p>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default observer(DevicesScreen);
