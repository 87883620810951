import { observer } from "mobx-react-lite";
import { useStores } from "src/stores";
import carePenguin from "src/services/carePenguin";
import { useEffect } from "react";

const ContactUsScreen = () => {
  const { login } = useStores();

  useEffect(() => {
    if (!login.loginObject) {
      console.log("[not logged in");
      carePenguin.doLogout();
      return;
    }
  }, [login.loginObject]);

  return (
    <div id="Dashboard" className="dashboard-page main-content">
      <div
        className="card-container grid"
        style={{
          backgroundColor: "white",
          marginTop: 20,
          width: "90%",
          marginLeft: "2.5%",
          padding: 10,
        }}
      >
        <h3 className="card-title">{`Contact Us`}</h3>
        <div className="card-component">
          <p className="location-text" style={{ marginTop: 0 }}>
            Have a question or need help? Don't hesitate to contact us!
            <br />
            <br />
            Email us at{" "}
            <a
              href="mailto:help@carepenguin.com"
              target="_blank"
              className="email_link"
              rel="noreferrer"
            >
              help@carepenguin.com
            </a>{" "}
            to get in touch with our friendly team.
            <br />
            <br />
            Check out our{" "}
            <a
              className="email_link"
              href="https://carepenguin.com/support/"
              target="_blank"
              rel="noreferrer"
            >
              Support Page
            </a>{" "}
            for answers to common questions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default observer(ContactUsScreen);
