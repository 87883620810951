import React, { useEffect, useState } from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import {
  emailValidator,
  requiredValidator,
  phoneValidator,
} from "../validators";

// import { AppContext } from "./../AppContext";
import { useStores } from "src/stores";
import { useServices } from "src/services";
import { Input } from "src/components/form/Input";
import { MaskedTextBox } from "src/components/form/MaskedTextBox";
import cpHeaderLogo from "../assets/images/CarePenguin_header_logo.png";

const SignUp = () => {
  // const { ...formValues } = React.useContext(AppContext);
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const queryParams = {
    name: query.get("name") || "",
    email: query.get("email") || "",
    phone: query.get("phoneNumber") || "",
  };
  const localizationService = useLocalization();
  const history = useHistory();

  const [signUpError, setSignUpError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { signUp } = useStores();
  const { carePenguin } = useServices();

  let location = useLocation<Location>();

  let { from } = (location.state as any) || { from: { pathname: "/" } };

  useEffect(() => {}, []);

  const onSubmit = React.useCallback(
    async (dataItem) => {
      if (
        dataItem.password !== dataItem.confirm_password ||
        dataItem.password.length < 5
      ) {
        setSignUpError(
          "Passwords must match and be at least 5 characters long"
        );
        return;
      }
      if (dataItem.email !== dataItem.confirm_email) {
        setSignUpError("Emails must match");
        return;
      }
      setIsLoading(true);
      signUp.setSignUpEmail(dataItem.email);
      signUp.setSignUpName(dataItem.name);
      signUp.setSignUpPassword(dataItem.password);
      signUp.setSignUpPhone(dataItem.phone);

      const call: any = await carePenguin.signUp(
        dataItem.email,
        dataItem.password,
        dataItem.name,
        dataItem.phone
      );
      if (call.result.errors) {
        console.log("[SignUp]", "signup Error");
        setSignUpError(call.result.errors.join("\n"));
        setIsLoading(false);
        return;
      }
      if (call.result.user.verified) {
        console.log("[SignUp]", "already verified, no 2fa required");
        setIsLoading(false);
        history.replace(from);
      }
      if (!call.result.user.verified) {
        console.log("[SignUp]", "code sent to email");
        setIsLoading(false);
        history.replace("/login", { ...location.state, ...call.result });
      }
    },
    [signUp, from, history, carePenguin, location.state]
  );

  return (
    <div id="signup" className="signup-page">
      <div className="card-container" style={{ maxWidth: 700 }}>
        <div className="card-component">
          <div className="header-img-container">
            <img
              src={cpHeaderLogo}
              alt="carepenguin header logo"
              className="cp-header-img"
            />
          </div>
          <Button onClick={() => history.push("/login")} className="back-btn">
            <span className="k-icon k-i-arrow-chevron-left"></span>Back to Login
          </Button>
          <p className="reset-header">Sign Up</p>
          <p className="reset-text">
            Fill out and submit the form below. Then, you will be redirected to
            the login page where you can login to verify your account.
          </p>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              email: queryParams.email,
              confirm_email: queryParams.email,
              name: queryParams.name,
              phone: queryParams.phone,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                {signUpError && (
                  <Error style={{ fontFamily: "Rubik-Regular" }}>
                    {signUpError}
                  </Error>
                )}
                <Field
                  id={"name"}
                  name={"name"}
                  type={"name"}
                  placeholder={"First Last"}
                  label={localizationService.toLanguageString(
                    "custom.name",
                    "Name"
                  )}
                  validator={requiredValidator}
                  required={true}
                  component={Input}
                />
                <Field
                  id={"email"}
                  name={"email"}
                  type={"email"}
                  placeholder={"e.g.: peter@gmail.com"}
                  label={localizationService.toLanguageString(
                    "custom.email",
                    "Email"
                  )}
                  validator={emailValidator}
                  required={true}
                  component={Input}
                  disabled={queryParams.email ? true : false}
                />
                <Field
                  id={"confirm_email"}
                  name={"confirm_email"}
                  type={"confirm_email"}
                  placeholder={"e.g.: peter@gmail.com"}
                  label={localizationService.toLanguageString(
                    "custom.confirmEmail",
                    "Confirm Email"
                  )}
                  validator={emailValidator}
                  required={true}
                  component={Input}
                  disabled={queryParams.email ? true : false}
                />
                <Field
                  id={"phone"}
                  name={"phone"}
                  type={"phone"}
                  mask="000-000-0000"
                  placeholder={"e.g.: 000-000-0000"}
                  label={localizationService.toLanguageString(
                    "custom.phone",
                    "Phone"
                  )}
                  validator={phoneValidator}
                  required={true}
                  component={MaskedTextBox}
                />
                <Field
                  id={"password"}
                  name={"password"}
                  type={"password"}
                  label={localizationService.toLanguageString(
                    "custom.password",
                    "Password"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <Field
                  id={"confirm_password"}
                  name={"confirm_password"}
                  type={"password"}
                  label={localizationService.toLanguageString(
                    "custom.confirm_password",
                    "Confirm Password"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <div className={"k-form-buttons"}>
                  <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit || isLoading}
                    className="signup-btn"
                  >
                    {localizationService.toLanguageString(
                      "custom.signUp",
                      "Sign Up"
                    )}
                  </Button>
                  {isLoading ? (
                    <Loader
                      style={{ color: "#4374B4" }}
                      type="infinite-spinner"
                    />
                  ) : null}
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
