import moment from "moment-timezone";

import { observer } from "mobx-react-lite";
// import { useLocalization } from "@progress/kendo-react-intl";
import { useStores } from "src/stores";
import carePenguin from "src/services/carePenguin";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import houseSunrise from "../assets/images/houseImages/Sunrise.png";
import houseNight from "../assets/images/houseImages/HouseNight.png";
import houseMorning from "../assets/images/houseImages/Morning.png";
import houseNoon from "../assets/images/houseImages/Noon.png";
import houseAfternoon from "../assets/images/houseImages/Afternoon.png";
import houseEvening from "../assets/images/houseImages/Evening.png";

const LocationsScreen = () => {
  // const localizationService = useLocalization();
  const history = useHistory();
  const { ui, login } = useStores();

  useEffect(() => {
    if (!login.loginObject) {
      console.log("[not logged in");
      carePenguin.doLogout();
      return;
    }
    carePenguin.getAccount();
  }, [login.loginObject]);

  const generateImageByTime = (timeZone: string) => {
    const locationTime = moment().tz(timeZone);
    const sunriseCutOff = moment().tz(timeZone).set("hour", 5).set("minute", 0);
    const morningCutoff = moment().tz(timeZone).set("hour", 7).set("minute", 0);
    const noonCutoff = moment().tz(timeZone).set("hour", 11).set("minute", 0);
    const afternoonCutoff = moment()
      .tz(timeZone)
      .set("hour", 14)
      .set("minute", 0);
    const eveningCutoff = moment()
      .tz(timeZone)
      .set("hour", 17)
      .set("minute", 0);
    const nightCutoff = moment().tz(timeZone).set("hour", 20).set("minute", 0);
    if (
      moment(locationTime).isBefore(morningCutoff) &&
      moment(locationTime).isAfter(sunriseCutOff)
    ) {
      return houseSunrise;
    } else if (
      moment(locationTime).isBefore(noonCutoff) &&
      moment(locationTime).isAfter(morningCutoff)
    ) {
      return houseMorning;
    } else if (
      moment(locationTime).isBefore(afternoonCutoff) &&
      moment(locationTime).isAfter(noonCutoff)
    ) {
      return houseNoon;
    } else if (
      moment(locationTime).isBefore(eveningCutoff) &&
      moment(locationTime).isAfter(afternoonCutoff)
    ) {
      return houseAfternoon;
    } else if (
      moment(locationTime).isBefore(nightCutoff) &&
      moment(locationTime).isAfter(eveningCutoff)
    ) {
      return houseEvening;
    } else if (moment(locationTime).isAfter(nightCutoff)) {
      return houseNight;
    }
    return houseAfternoon;
  };

  const generateStatus = (locationState: string) => {
    let message = "";
    if (locationState === "CRITICAL") {
      message = "Activity not detected lately.";
    } else if (locationState === "WARNING") {
      message = "Activity is a little overdue.";
    } else if (locationState === "SUPPRESSED") {
      message = "Activity check is suppressed.";
    } else if (locationState === "PAUSED") {
      message = "Activity checking has been paused.";
    } else if (locationState === "NORMAL") {
      message = "Activity is normal.";
    }
    return message;
  };

  const renderLastActivityMessage = (location: CPLocation) => {
    if (!location.firstWaterUsageToday || !location.sensors.length) {
      return <p className="last-activity-text">No prior activity is known.</p>;
    }
    let lastActivity = location.firstWaterUsageToday
      ? moment(location.firstWaterUsageToday).tz(location.timezone)
      : moment(location.lastWaterUsage).tz(location.timezone);
    if (
      !location.firstWaterUsageTodayAfter2AM &&
      location.firstWaterUsageToday
    ) {
      const lastUsageTime = lastActivity.format("h:mm a");
      const timeAgo = lastActivity.fromNow();
      return (
        <p className="last-activity-text">
          Last activity was detected at {lastUsageTime}, {timeAgo}.
        </p>
      );
    }
    if (location.firstWaterUsageTodayAfter2AM) {
      lastActivity = moment(location.firstWaterUsageTodayAfter2AM).tz(
        location.timezone
      );
    }
    if (!lastActivity || !location.sensors.length) {
      return <p className="last-activity-text">No prior activity is known.</p>;
    }
    if (lastActivity.isSame(moment().tz(location.timezone), "day")) {
      const lastUsageTime = lastActivity.format("h:mm a");
      return (
        <p className="last-activity-text">
          First activity this morning occured at {lastUsageTime}.
        </p>
      );
    }
    if (!lastActivity.isSame(moment().tz(location.timezone), "day")) {
      const lastUsageTime = lastActivity.format("h:mm a");
      const lastDate = lastActivity.format("MMM Do");
      return (
        <p className="last-activity-text">
          Last activity was detected at {lastUsageTime} on {lastDate}.
        </p>
      );
    }
  };

  const calculateTimeAgo = (
    fromTime: object,
    timeZone: string,
    location: CPLocation
  ) => {
    const hoursAgo = moment().tz(timeZone).diff(fromTime, "hours");
    const minutesAgo = moment().tz(timeZone).diff(fromTime, "minutes");
    if (hoursAgo === 0) {
      return `${minutesAgo} minutes ago`;
    } else if (hoursAgo > 0) {
      const minutesRemainder = minutesAgo - 60 * hoursAgo;
      return `${hoursAgo} hours and ${minutesRemainder} minutes ago`;
    }
    return `${moment(location.lastWaterUsageToday)
      .tz(location.timezone)
      .fromNow()}`;
  };

  const renderLocation = (location: CPLocation) => {
    const currentUserId = login.loginObject?.user?.id;
    const accountHolderID = ui.account?.accountOwnerUserId;
    if (currentUserId === accountHolderID) {
      ui.setIsAccountOwner(true);
    } else {
      ui.setIsAccountOwner(false);
    }
    const houseImage = generateImageByTime(location.timezone);
    const locationColor: string = location.state.color;
    const locationState: string = location.state.state;
    const colors: ColorsObject = {
      GREEN: "#A5C174",
      YELLOW: "#EDF443",
      GRAY: "#B0B0AF",
      RED: "#EF4F30",
      ORANGE: "#EFA830",
      MAROON: "#620436",
      SUPPRESSED: "#620436",
    };
    const borderColor = colors[locationColor as keyof ColorsObject];
    return (
      <div
        onClick={() => history.push(`/devices/${location.id}`)}
        key={location.id}
        className="location-card-body"
        style={{ borderLeft: `3px solid ${borderColor}` }}
      >
        <div className="card-header">
          <h3 className="location-name">{location.name}</h3>
          <h3 style={{ color: "#12384C", fontSize: "15px" }}>
            ({moment().tz(location.timezone).format("h:mm a")})
          </h3>
        </div>
        <div>
          <p
            style={{
              color: borderColor,
              fontWeight: 600,
              fontFamily: "Rubik-Medium",
            }}
          >
            {generateStatus(locationState)}
          </p>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={houseImage}
              style={{ height: 125 }}
              alt="house at a certain time of day"
            />
          </div>
          {renderLastActivityMessage(location)}
          {location.usagesToday === 0 ? (
            <p className="last-activity-text">{`There have been no activities detected so far today.`}</p>
          ) : null}
          {location.usagesToday !== undefined && location.usagesToday ? (
            <p className="last-activity-text">{`There have been ${
              location.usagesToday
            } usage(s) detected today. Last activity was about ${calculateTimeAgo(
              moment(location.lastWaterUsageToday).tz(location.timezone),
              location.timezone,
              location
            )}.`}</p>
          ) : null}
          <p className="last-activity-text">
            {" "}
            {`(Timezone: ${location.timezone})`}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div id="Dashboard" className="dashboard-page main-content">
      <div className="card-container grid">
        <h3 className="card-title">{`${ui.account?.account}: Locations`}</h3>
        <div className="card-component">
          <p
            className="location-text"
            style={
              !ui.isGettingAccount && !ui.account?.locations?.length
                ? { display: "none" }
                : {}
            }
          >
            Click on a location to view the sensors installed at that location.
          </p>
          {ui.account?.locations?.length ? (
            <div className="location-cards-wrapper">
              {ui.account?.locations.map((location: CPLocation) =>
                renderLocation(location)
              )}
            </div>
          ) : ui.isGettingAccount && !ui.account?.locations?.length ? (
            <Loader type="infinite-spinner" />
          ) : (
            <p className="location-text">
              You do not have any locations associated with this account. Please
              download the CarePenguin mobile app from the Apple App Store or
              the Google Play Store to create your first location and install
              your sensor.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(LocationsScreen);
