import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { HydratedStore } from 'src/utils/classes';

class UIStore extends HydratedStore {
  constructor() {
    super('UIStore');
    makeObservable(this);
  }

  @observable appVersion: string = '%REACTBUILDVERSION%';

  @observable isPremiumAccount: boolean = true;
  @action setIsPremiumAccount = (value: boolean) => this.isPremiumAccount = value;

  // login screen
  @observable isLoggingIn: boolean = false;
  @action setIsLoggingIn = (value: boolean) => this.isLoggingIn = value;

  @observable loginError: string = '';
  @action setLoginError = (value: string) => this.loginError = value;

  @observable isGettingAccount: boolean = false;
  @action setIsGettingAccount = (value: boolean) => this.isGettingAccount = value;

  @observable isGettingBilling: boolean = false;
  @action setIsGettingBilling = (value: boolean) => this.isGettingBilling = value;

  @observable billingExists: boolean = false;
  @action setBillingExists = (value: boolean) => this.billingExists = value;

  @observable isAPIProcessing: boolean = false;
  @action setIsAPIProcessing = (value: boolean) => this.isAPIProcessing = value;

  @observable isLoggingOut: boolean = false;
  @action setIsLoggingOut = (value: boolean) => this.isLoggingOut = value;

  @observable showAcceptInviteModal: boolean = false;
  @action toggleAcceptInviteModal = (value: boolean) => this.showAcceptInviteModal = value;

  @observable createUserBtnDisabled: boolean = true;
  @action setCreateUserBtnDisabled = (value: boolean ) => this.createUserBtnDisabled = value;

  @observable showResetPasswordModal: boolean = false;
  @action toggleResetPasswordModal = (value: boolean) => this.showResetPasswordModal = value;

  @observable resetPasswordBtnDisabled: boolean = true;
  @action setResetPasswordBtnDisabled = (value: boolean ) => this.resetPasswordBtnDisabled = value;

  @observable resetPasswordEmail: string = '';
  @action setResetPasswordEmail = (value: string) => this.resetPasswordEmail = value;

  @observable resetPasswordPass: string = '';
  @action setResetPasswordPass = (value: string) => this.resetPasswordPass = value;
  
  @observable resetPasswordConfirmPass: string = '';
  @action setResetPasswordConfirmPass = (value: string) => this.resetPasswordConfirmPass = value;

  @observable resetPasswordError: string = '';
  @action setResetPasswordError = (value: string) => this.resetPasswordError = value;

  @observable isValidatingReset: boolean = false;
  @action setIsValidatingReset = (value: boolean) => this.isValidatingReset = value;

  @observable acceptInviteFirstName: string = '';
  @action setAcceptInviteFirstName = (value: string) => this.acceptInviteFirstName = value;
  
  @observable acceptInviteLastName: string = '';
  @action setAcceptInviteLastName = (value: string) => this.acceptInviteLastName = value;
  
  @observable acceptInviteEmail: string = '';
  @action setAcceptInviteEmail = (value: string) => this.acceptInviteEmail = value;
  
  @observable acceptInviteCode: string = '';
  @action setAcceptInviteCode = (value: string) => this.acceptInviteCode = value;
  
  @observable acceptInvitePass: string = '';
  @action setAcceptInvitePass = (value: string) => this.acceptInvitePass = value;
  
  @observable acceptInviteConfirmPass: string = '';
  @action setAcceptInviteConfirmPass = (value: string) => this.acceptInviteConfirmPass = value;

  @observable showFailedInviteModal: boolean = false;
  @action toggleShowFailedInviteModal = (value: boolean) => this.showFailedInviteModal = value;

  // caregiver screen
  @observable memberList: Member[] = [];
  @action setMemberList = (value: Member[]) => this.memberList = value;

  @observable invitesList: Invite[] = [];
  @action setInvitesList = (value: Invite[]) => this.invitesList = value;

  @observable showInviteModal: boolean = false;
  @action toggleInviteModal = (value: boolean) => this.showInviteModal = value;

  @observable inviteePhoneNumber: string = '';
  @action setInviteePhoneNumber = (value: string ) => this.inviteePhoneNumber = value;
  
  @observable inviteeCountryCode: string = '1';
  @action setInviteeCountryCode = (value: string ) => this.inviteeCountryCode = value;

  @observable isAccountOwner: boolean = false;
  @action setIsAccountOwner = (value: boolean) => this.isAccountOwner = value;

  // My Account
  @observable userName: string = '';
  @action setUserName = (value: string ) => this.userName = value;

  @observable userEmail: string = '';
  @action setUserEmail = (value: string ) => this.userEmail = value;
  
  // @observable userPhone: string = '';
  // @action setUserPhone = (value: string ) => this.userPhone = value;

  // Locations Screen
  @persist('object') @observable visitedScreens: Record<ScreenName, number> = {
    login: 0,
    verify: 0,
    locations: 0,
    notifications: 0,
    caregivers: 0,
    sensors: 0,
    settings: 0,
    addLocation: 0,
    deviceWizardStart: 0,
    deviceWizardHelp: 0,
    deviceWizardConnect: 0,
    deviceWizardWiFi: 0,
    deviceWizardFinish: 0,
    deviceWizardIntro: 0,
    deviceWizardPipe: 0,
    deviceWizardComplete: 0,
    appUpdates: 0,
    loadingScreen: 0
  };
  @action setVisitedScreens = (value: Record<ScreenName, number>) => this.visitedScreens = value;

  @observable showLocationCardMenu: boolean = false;
  @action toggleLocationCardMenu = (value: boolean) => this.showLocationCardMenu = value;

  @observable showEditLocationModal: boolean = false;
  @action toggleEditLocationModal = (value: boolean) => this.showEditLocationModal = value;

  @observable showExpectedTimesModal: boolean = false;
  @action toggleExpectedTimesModal = (value: boolean) => this.showExpectedTimesModal = value;

  @observable editLocationId: number = 0;
  @action setEditLocationId = (value: number ) => this.editLocationId = value;  
  
  @observable editLocationBtnDisabled: boolean = true;
  @action setEditLocationBtnDisabled = (value: boolean ) => this.editLocationBtnDisabled = value;

  @observable appUpdateNeeded: boolean = false;
  @action setAppUpdateNeeded = (value: boolean ) => this.appUpdateNeeded = value;
  
  @observable statusColor: string = 'NORMAL';
  @action setStatusColor = (value: string) => this.statusColor = value;

  @observable expectedTimesData: ExpectedTime[] = [];
  @action setExpectedTimesData = (value: ExpectedTime[]) => this.expectedTimesData = value;

  @observable expectedTimesConfig?: ExpectedTimesConfig;
  @action setExpectedTimesConfig = (value: ExpectedTimesConfig) => this.expectedTimesConfig = value;

  @observable expectedTimesLocationId: number = 0;
  @action setExpectedTimesLocationId = (value: number ) => this.expectedTimesLocationId = value;  

  // Sensor Screen
  @observable sensorSegment: string = 'water_use';
  @action setSensorSegment = (value: string) => this.sensorSegment = value;

  @observable showEditSensorModal: boolean = false;
  @action toggleEditSensorModal = (value: boolean) => this.showEditSensorModal = value;

  @observable editSensorBtnDisabled: boolean = true;
  @action setEditSensorBtnDisabled = (value: boolean ) => this.editSensorBtnDisabled = value;
  
  @observable sensorName: string = '';
  @action setSensorName = (value: string) => this.sensorName = value;

  @observable selectedLocationName: string = '';
  @action setSelectedLocationName = (value: string) => this.selectedLocationName = value;

  @observable sensorEvents: SensorEvent[] = [];
  @action setSensorEvents = (value: SensorEvent[]) => this.sensorEvents = value;

  @observable selectedSensorEdit?: Sensor;
  @action setSelectedSensorEdit = (value: Sensor) => this.selectedSensorEdit = value;

  @observable isSensorDataLoading: boolean = true;
  @action setIsSensorDataLoading = (value: boolean ) => this.isSensorDataLoading = value;
  
  @observable hasFetchedSensorData: boolean = true;
  @action setHasFetchedSensorData = (value: boolean ) => this.hasFetchedSensorData = value;

  // Notifications Screen
  @observable notificationsList: Notification[] = [];
  @action setNotificationsList = (value: Notification[]) => this.notificationsList = value;

  @observable notificationsSegment: string = 'all';
  @action setNotificationsSegment = (value: string) => this.notificationsSegment = value;

  @observable tokenStringTemp: string = '';
  @action setTokenStringTemp = (value: string) => this.tokenStringTemp = value;

  @persist @observable isUnreadNotification: boolean = false;
  @action setIsUnreadNotification = (value: boolean) => this.isUnreadNotification = value;

  // add location
  @observable isAddingLocation: boolean = false;
  @action setIsAddingLocation = (value: boolean) => this.isAddingLocation = value;

  @observable locationError: string = '';
  @action setLocationError = (value: string) => this.locationError = value;

  @observable locationName: string = '';
  @action setLocationName = (value: string) => this.locationName = value;

  @observable locationCoords?: Coordinates;
  @action setLocationCoords = (value?: Coordinates) => this.locationCoords = value;

  @persist('object') @observable account?: Account;
  @action setAccount = (value?: Account) => this.account = value;

  @observable sensorData: Sensor[] = [];
  @action setSensorData = (value: Sensor[]) => this.sensorData = value;

  // sensor connecting
  @observable newSensorID: number = 0;
  @action setNewSensorID = (value: number) => this.newSensorID = value;

  @observable isWiFiConnecting: number = 0;
  @action setIsWiFiConnecting = (value: number) => this.isWiFiConnecting = value;

  @observable isDHCPConnecting: number = 0;
  @action setIsDHCPConnecting = (value: number) => this.isDHCPConnecting = value;

  @observable isSensorConnecting: number = 0;
  @action setIsSensorConnecting = (value: number) => this.isSensorConnecting = value;

  @observable isSensorReading: number = 0;
  @action setIsSensorReading = (value: number) => this.isSensorReading = value;

  @observable helpPage?: HelpPageType;
  @action setHelpPage = (value: HelpPageType) => this.helpPage = value;

  // sensor configuring
  @observable isInstallingSensor: boolean = false;
  @action toggleIsInstallingSensor = (value: boolean) => this.isInstallingSensor = value;

  @observable sensorDeviceID: string = '';
  @action setSensorDeviceID = (value: string) => this.sensorDeviceID = value;

  @observable wiFiList: Record<string,WiFiNetwork> = {};
  @action setWiFiList = (value: Record<string,WiFiNetwork>) => this.wiFiList = value;
  
  @observable selectedNetwork?: WiFiNetwork;
  @action setSelectedNetwork = (value?: WiFiNetwork) => this.selectedNetwork = value;
  
  @observable networkPassword: string = '';
  @action setNetworkPassword = (value: string) => this.networkPassword = value;
  
  @observable isSensorSaving: number = 0;
  @action setIsSensorSaving = (value: number) => this.isSensorSaving = value;
  
  @observable isSensorConnectingToWiFi: number = 0;
  @action setIsSensorConnectingToWiFi = (value: number) => this.isSensorConnectingToWiFi = value;
  
  @observable isPhoneDisconnectingSetup: number = 0;
  @action setIsPhoneDisconnectingSetup = (value: number) => this.isPhoneDisconnectingSetup = value;
  
  @observable isSensorAddingToAccount: number = 0;
  @action setIsSensorAddingToAccount = (value: number) => this.isSensorAddingToAccount = value;
 
  @observable isWaitingForSensorData: number = 0;
  @action setIsWaitingForSensorData = (value: number) => this.isWaitingForSensorData = value;
 
  @observable isInstallComplete: number = 0;
  @action setIsInstallComplete = (value: number) => this.isInstallComplete = value;

  @observable networkType?: string = '';
  @observable componentId: string = ''; // current component id
  @observable isCheckingForAppUpdates: boolean = false;

  @action setNetworkType = (value?: string) => this.networkType = value;
  @action setComponentId = (value: string) => this.componentId = value;
  @action setIsCheckingForAppUpdates = (value: boolean) => this.isCheckingForAppUpdates = value;

  @observable locationTimezoneList: Array<string> = [
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "Africa/Asmara",
    "Africa/Bamako",
    "Africa/Bangui",
    "Africa/Banjul",
    "Africa/Bissau",
    "Africa/Blantyre",
    "Africa/Brazzaville",
    "Africa/Bujumbura",
    "Africa/Cairo",
    "Africa/Casablanca",
    "Africa/Ceuta",
    "Africa/Conakry",
    "Africa/Dakar",
    "Africa/Dar_es_Salaam",
    "Africa/Djibouti",
    "Africa/Douala",
    "Africa/El_Aaiun",
    "Africa/Freetown",
    "Africa/Gaborone",
    "Africa/Harare",
    "Africa/Johannesburg",
    "Africa/Juba",
    "Africa/Kampala",
    "Africa/Khartoum",
    "Africa/Kigali",
    "Africa/Kinshasa",
    "Africa/Lagos",
    "Africa/Libreville",
    "Africa/Lome",
    "Africa/Luanda",
    "Africa/Lubumbashi",
    "Africa/Lusaka",
    "Africa/Malabo",
    "Africa/Maputo",
    "Africa/Maseru",
    "Africa/Mbabane",
    "Africa/Mogadishu",
    "Africa/Monrovia",
    "Africa/Nairobi",
    "Africa/Ndjamena",
    "Africa/Niamey",
    "Africa/Nouakchott",
    "Africa/Ouagadougou",
    "Africa/Porto-Novo",
    "Africa/Sao_Tome",
    "Africa/Tripoli",
    "Africa/Tunis",
    "Africa/Windhoek",

    "America/Adak",
    "America/Anchorage",
    "America/Anguilla",
    "America/Antigua",
    "America/Araguaina",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia",
    "America/Aruba",
    "America/Asuncion",
    "America/Atikokan",
    "America/Bahia",
    "America/Bahia_Banderas",
    "America/Barbados",
    "America/Belem",
    "America/Belize",
    "America/Blanc-Sablon",
    "America/Boa_Vista",
    "America/Bogota",
    "America/Boise",
    "America/Cambridge_Bay",
    "America/Campo_Grande",
    "America/Cancun",
    "America/Caracas",
    "America/Cayenne",
    "America/Cayman",
    "America/Chicago",
    "America/Chihuahua",
    "America/Costa_Rica",
    "America/Creston",
    "America/Cuiaba",
    "America/Curacao",
    "America/Danmarkshavn",
    "America/Dawson",
    "America/Dawson_Creek",
    "America/Denver",
    "America/Detroit",
    "America/Dominica",
    "America/Edmonton",
    "America/Eirunepe",
    "America/El_Salvador",
    "America/Fort_Nelson",
    "America/Fortaleza",
    "America/Glace_Bay",
    "America/Godthab",
    "America/Goose_Bay",
    "America/Grand_Turk",
    "America/Grenada",
    "America/Guadeloupe",
    "America/Guatemala",
    "America/Guayaquil",
    "America/Guyana",
    "America/Halifax",
    "America/Havana",
    "America/Hermosillo",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Inuvik",
    "America/Iqaluit",
    "America/Jamaica",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Kralendijk",
    "America/La_Paz",
    "America/Lima",
    "America/Los_Angeles",
    "America/Lower_Princes",
    "America/Maceio",
    "America/Managua",
    "America/Manaus",
    "America/Marigot",
    "America/Martinique",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Menominee",
    "America/Merida",
    "America/Metlakatla",
    "America/Mexico_City",
    "America/Miquelon",
    "America/Moncton",
    "America/Monterrey",
    "America/Montevideo",
    "America/Montreal",
    "America/Montserrat",
    "America/Nassau",
    "America/New_York",
    "America/Nipigon",
    "America/Nome",
    "America/Noronha",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Nuuk",
    "America/Ojinaga",
    "America/Panama",
    "America/Pangnirtung",
    "America/Paramaribo",
    "America/Phoenix",
    "America/Port-au-Prince",
    "America/Port_of_Spain",
    "America/Porto_Velho",
    "America/Puerto_Rico",
    "America/Punta_Arenas",
    "America/Rainy_River",
    "America/Rankin_Inlet",
    "America/Recife",
    "America/Regina",
    "America/Resolute",
    "America/Rio_Branco",
    "America/Santa_Isabel",
    "America/Santarem",
    "America/Santiago",
    "America/Santo_Domingo",
    "America/Sao_Paulo",
    "America/Scoresbysund",
    "America/Shiprock",
    "America/Sitka",
    "America/St_Barthelemy",
    "America/St_Johns",
    "America/St_Kitts",
    "America/St_Lucia",
    "America/St_Thomas",
    "America/St_Vincent",
    "America/Swift_Current",
    "America/Tegucigalpa",
    "America/Thule",
    "America/Thunder_Bay",
    "America/Tijuana",
    "America/Toronto",
    "America/Tortola",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yakutat",
    "America/Yellowknife",

    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Macquarie",
    "Antarctica/Mawson",
    "Antarctica/McMurdo",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/South_Pole",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "Arctic/Longyearbyen",

    "Asia/Aden",
    "Asia/Almaty",
    "Asia/Amman",
    "Asia/Anadyr",
    "Asia/Aqtau",
    "Asia/Aqtobe",
    "Asia/Ashgabat",
    "Asia/Atyrau",
    "Asia/Baghdad",
    "Asia/Bahrain",
    "Asia/Baku",
    "Asia/Bangkok",
    "Asia/Barnaul",
    "Asia/Beirut",
    "Asia/Bishkek",
    "Asia/Brunei",
    "Asia/Calcutta",
    "Asia/Chita",
    "Asia/Choibalsan",
    "Asia/Chongqing",
    "Asia/Colombo",
    "Asia/Damascus",
    "Asia/Dhaka",
    "Asia/Dili",
    "Asia/Dubai",
    "Asia/Dushanbe",
    "Asia/Famagusta",
    "Asia/Gaza",
    "Asia/Harbin",
    "Asia/Hebron",
    "Asia/Ho_Chi_Minh",
    "Asia/Hong_Kong",
    "Asia/Hovd",
    "Asia/Irkutsk",
    "Asia/Jakarta",
    "Asia/Jayapura",
    "Asia/Jerusalem",
    "Asia/Kabul",
    "Asia/Kamchatka",
    "Asia/Karachi",
    "Asia/Kashgar",
    "Asia/Kathmandu",
    "Asia/Katmandu",
    "Asia/Khandyga",
    "Asia/Krasnoyarsk",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Asia/Kuwait",
    "Asia/Macau",
    "Asia/Magadan",
    "Asia/Makassar",
    "Asia/Manila",
    "Asia/Muscat",
    "Asia/Nicosia",
    "Asia/Novokuznetsk",
    "Asia/Novosibirsk",
    "Asia/Omsk",
    "Asia/Oral",
    "Asia/Phnom_Penh",
    "Asia/Pontianak",
    "Asia/Pyongyang",
    "Asia/Qatar",
    "Asia/Qostanay",
    "Asia/Qyzylorda",
    "Asia/Rangoon",
    "Asia/Riyadh",
    "Asia/Sakhalin",
    "Asia/Samarkand",
    "Asia/Seoul",
    "Asia/Shanghai",
    "Asia/Singapore",
    "Asia/Srednekolymsk",
    "Asia/Taipei",
    "Asia/Tashkent",
    "Asia/Tbilisi",
    "Asia/Tehran",
    "Asia/Thimphu",
    "Asia/Tokyo",
    "Asia/Tomsk",
    "Asia/Ulaanbaatar",
    "Asia/Urumqi",
    "Asia/Ust-Nera",
    "Asia/Vientiane",
    "Asia/Vladivostok",
    "Asia/Yakutsk",
    "Asia/Yangon",
    "Asia/Yekaterinburg",
    "Asia/Yerevan",

    "Atlantic/Azores",
    "Atlantic/Bermuda",
    "Atlantic/Canary",
    "Atlantic/Cape_Verde",
    "Atlantic/Faroe",
    "Atlantic/Madeira",
    "Atlantic/Reykjavik",
    "Atlantic/South_Georgia",
    "Atlantic/St_Helena",
    "Atlantic/Stanley",

    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Broken_Hill",
    "Australia/Currie",
    "Australia/Darwin",
    "Australia/Eucla",
    "Australia/Hobart",
    "Australia/Lindeman",
    "Australia/Lord_Howe",
    "Australia/Melbourne",
    "Australia/Perth",
    "Australia/Sydney",

    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zaporozhye",
    "Europe/Zurich",

    "GMT",

    "Indian/Antananarivo",
    "Indian/Chagos",
    "Indian/Christmas",
    "Indian/Cocos",
    "Indian/Comoro",
    "Indian/Kerguelen",
    "Indian/Mahe",
    "Indian/Maldives",
    "Indian/Mauritius",
    "Indian/Mayotte",
    "Indian/Reunion",

    "Pacific/Apia",
    "Pacific/Auckland",
    "Pacific/Bougainville",
    "Pacific/Chatham",
    "Pacific/Chuuk",
    "Pacific/Easter",
    "Pacific/Efate",
    "Pacific/Enderbury",
    "Pacific/Fakaofo",
    "Pacific/Fiji",
    "Pacific/Funafuti",
    "Pacific/Galapagos",
    "Pacific/Gambier",
    "Pacific/Guadalcanal",
    "Pacific/Guam",
    "Pacific/Honolulu",
    "Pacific/Johnston",
    "Pacific/Kiritimati",
    "Pacific/Kosrae",
    "Pacific/Kwajalein",
    "Pacific/Majuro",
    "Pacific/Marquesas",
    "Pacific/Midway",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Norfolk",
    "Pacific/Noumea",
    "Pacific/Pago_Pago",
    "Pacific/Palau",
    "Pacific/Pitcairn",
    "Pacific/Pohnpei",
    "Pacific/Ponape",
    "Pacific/Port_Moresby",
    "Pacific/Rarotonga",
    "Pacific/Saipan",
    "Pacific/Tahiti",
    "Pacific/Tarawa",
    "Pacific/Tongatapu",
    "Pacific/Truk",
    "Pacific/Wake",
    "Pacific/Wallis"
  ];
}

export default new UIStore();
