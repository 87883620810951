import React, { useEffect, useState } from "react";
import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import { emailValidator, requiredValidator } from "../validators";
import { AppContext } from "./../AppContext";
import { useStores } from "src/stores";
import { useServices } from "src/services";
import { Input } from "src/components/form/Input";
import cpHeaderLogo from "../assets/images/CarePenguin_header_logo.png";
import waves from "../assets/images/Customer_Portal_Landing_Waves_SVG.svg";

const LoginScreen = () => {
  const { ...formValues } = React.useContext(AppContext);
  const localizationService = useLocalization();
  const history = useHistory();

  const [loginError, setLoginError] = useState<string>();
  const { login } = useStores();
  const { carePenguin } = useServices();

  let location = useLocation<Location>();

  let { from } = (location.state as any) || { from: { pathname: "/" } };

  useEffect(() => {
    if (login.loginObject) {
      console.log("[LoginScreen]", "I think we are already logged in");
      carePenguin.getAccount().then(() => {
        history.replace("/");
      });
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = React.useCallback(
    async (dataItem) => {
      login.setLoginEmail(dataItem.email);
      const call: any = await carePenguin.doLogin(
        dataItem.email,
        dataItem.password
      );
      // if (
      //   call.result.code === "USER_VERIFICATION_NEEDED" &&
      //   !call.result.errors.length
      // ) {
      //   signUp.setSignUpEmail(dataItem.email);
      //   history.replace("/2fa_signUp", { ...location.state, ...call.result });
      //   return;
      // }
      if (call.result.errors) {
        console.log("[LoginScreen]", "Login Error");
        setLoginError(call.result.errors.join("\n"));
        return;
      }
      if (call.result.user) {
        console.log("[LoginScreen]", "logged in, no 2fa required");
        login.setLoginObject(call.result as Login);
        await carePenguin.getAccount();
        history.replace(from);
      }
      if (
        call.result.sentToObfuscatedNumber ||
        call.result.type === "opt_sent"
      ) {
        console.log("[LoginScreen]", "code sent to phone");
        login.setLoginPhone(call.result.sentToObfuscatedNumber);
        return history.replace("/2fa", { ...location.state, ...call.result });
      }
    },
    [login, carePenguin, history, location.state, from]
  );

  return (
    <div id="Login" className="login-page">
      <div className="card-container">
        <div className="card-component">
          {/* <h3 className="login-header">CarePenguin Portal Login</h3>
           */}
          <div className="header-img-container">
            <img
              src={cpHeaderLogo}
              alt="carepenguin header logo"
              className="cp-header-img"
            />
          </div>
          <p className="customer_login_txt">Customer Login</p>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              ...formValues,
              email: login.loginEmail,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                {loginError && <Error>{loginError}</Error>}
                <Field
                  id={"email"}
                  name={"email"}
                  type={"email"}
                  placeholder={"e.g.: peter@gmail.com"}
                  label={localizationService.toLanguageString(
                    "custom.email",
                    "Email"
                  )}
                  validator={emailValidator}
                  required={true}
                  component={Input}
                />
                <Field
                  id={"password"}
                  name={"password"}
                  type={"password"}
                  label={localizationService.toLanguageString(
                    "custom.password",
                    "Password"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <div className={"k-form-buttons login-btn"}>
                  <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                    style={{
                      width: "90%",
                      fontWeight: 600,
                      fontFamily: "Rubik-Medium",
                      borderRadius: 15,
                      backgroundColor: "#4374B4",
                      borderColor: "#4374B4",
                      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
                      margin: 5,
                      marginBottom: 15,
                    }}
                  >
                    {localizationService.toLanguageString(
                      "custom.logIn",
                      "Login"
                    )}
                  </Button>
                </div>
              </FormElement>
            )}
          />
          <div className={"k-form-buttons login-btn"}>
            <Button
              primary={true}
              type={"submit"}
              onClick={() => history.push("/signUp")}
              style={{
                width: "90%",
                fontWeight: 600,
                fontFamily: "Rubik-Medium",
                borderRadius: 15,
                backgroundColor: "#F5F6F6",
                borderColor: "#F5F6F6",
                color: "black",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
                margin: 5,
              }}
            >
              {localizationService.toLanguageString(
                "custom.createAccount",
                "Create an Account"
              )}
            </Button>
          </div>
          <div className={"k-form-buttons forgot-pass-btn"}>
            <Button
              primary={true}
              // type={"submit"}
              // disabled={!formRenderProps.allowSubmit}
              style={{ width: "100%", fontFamily: "Inter-Medium" }}
              className="forgot-pass-inner"
              onClick={() => history.push("/forgotPassword")}
            >
              {localizationService.toLanguageString(
                "custom.forgotPassword",
                "Forgot Password"
              )}
            </Button>
          </div>
          {/* <div className="sign-up-block"> */}
          {/* <p className="sign-up-text"></p> */}
          {/* <div className={"sign-up-btn-container"}> */}
          {/* <Button
              onClick={() => history.push("/signUp")}
              className="sign-up-btn"
            > */}
          {/* {localizationService.toLanguageString(
                  "custom.signUp",
                  "Sign Up"
                )} */}
          {/* Don't have an account? Click here to sign up now!
            </Button> */}
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
      <img src={waves} alt="carepenguin header logo" className="login-waves" />
    </div>
  );
};

export default LoginScreen;
