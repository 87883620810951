export const enMessages = {
  custom: {
    carePenguin: "CarePenguin",

    myLocations: "My Locations",
    myAccount: "My Account",
    orderSensor: "Order Sensor",
    help: "Help",
    contact: "Contact Us",

    logIn: "Log In",
    logout: "Log Out",
    verificationCode: "Verification Code",

    teamEfficiency: "Team Efficiency",
    teamMembers: "Team Members",
    dashboard: "Dashboard",
    planning: "Planning",
    profile: "Profile",
    info: "Info",
    language: "Language",
    trend: "Trend",
    volume: "Volume",
    myTeam: "My Team",
    allTeams: "All Teams",
    teamCalendar: "Team Calendar",
    saveChanges: "Save Changes",
    cancel: "Cancel",
    startFreeTrial: "Start Free Trial",
    buyNow: "Buy Now",
    getSource: "Get the source code on GitHub",
    demoInfo:
      "This demo application is built using the following KendoReact components and the KendoReact Default Theme.",
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    email: "Email Address",
    phoneNumber: "Phone Number",
    country: "Country",
    biography: "Short Biography",
    public: "Include in public directory",
    team: "Team",
    employee: "Employee",
    contactName: "Contact Name",
    jobTitle: "Job Title",
    status: "Status",
    performance: "Performance",
    rating: "Rating",
    engagement: "Engagement",
    budget: "Budget",
    contacts: "Contacts",
    address: "Address",
    phone: "Phone",
    gridSearch: "Search in all columns...",
    exportExcel: "Export to Excel",
    exportPdf: "Export to PDF",
    submit: "Submit",
  },
  grid: {
    groupPanelEmpty:
      "Drag a column header and drop it here to group by that column",
    pagerItemsPerPage: "items per page",
    pagerInfo: "{0} - {1} of {2} items",
    pagerFirstPage: "Go to the first page",
    pagerPreviousPage: "Go to the previous page",
    pagerNextPage: "Go to the next page",
    pagerLastPage: "Go to the last page",
    pagerPage: "Page",
    pagerOf: "of",
    filterClearButton: "Clear",
    filterEqOperator: "Is equal to",
    filterNotEqOperator: "Is not equal to",
    filterIsNullOperator: "Is null",
    filterIsNotNullOperator: "Is not null",
    filterIsEmptyOperator: "Is empty",
    filterIsNotEmptyOperator: "Is not empty",
    filterStartsWithOperator: "Starts with",
    filterContainsOperator: "Contains",
    filterNotContainsOperator: "Does not contain",
    filterEndsWithOperator: "Ends with",
    filterGteOperator: "Is greater than or equal to",
    filterGtOperator: "Is greater than",
    filterLteOperator: "Is less than or equal to",
    filterLtOperator: "Is less than",
    filterIsTrue: "Is true",
    filterIsFalse: "Is false",
    filterBooleanAll: "(All)",
    filterAfterOrEqualOperator: "Is after or equal to",
    filterAfterOperator: "Is after",
    filterBeforeOperator: "Is before",
    filterBeforeOrEqualOperator: "Is before or equal to",
    noRecords: "No records available.",
  },
  filter: {
    eqOperator: "Is equal to",
    notEqOperator: "Is not equal to",
    isNullOperator: "Is null",
    isNotNullOperator: "Is not null",
    isEmptyOperator: "Is empty",
    isNotEmptyOperator: "Is not empty",
    startsWithOperator: "Starts with",
    containsOperator: "Contains",
    notContainsOperator: "Does not contain",
    endsWithOperator: "Ends with",
    gteOperator: "Is greater than or equal to",
    gtOperator: "Is greater than",
    lteOperator: "Is less than or equal to",
    ltOperator: "Is less than",
    isTrue: "Is true",
    isFalse: "Is false",
    afterOrEqualOperator: "Is after or equal to",
    afterOperator: "Is after",
    beforeOperator: "Is before",
    beforeOrEqualOperator: "Is before or equal to",
    andLogic: "And",
    orLogic: "Or",
    addExpression: "Add Expression",
    addGroup: "Add Group",
    close: "Close",
  },
  dateinput: {
    increment: "Increase value",
    decrement: "Decrease value",
  },
  calendar: {
    today: "TODAY",
  },
  datepicker: {
    toggleCalendar: "Toggle calendar",
  },
  daterangepicker: {
    swapStartEnd: "Swap start and end values",
    start: "Start",
    end: "End",
    separator: " ",
  },
  datetimepicker: {
    date: "Date",
    time: "Time",
    toggleDateTimeSelector: "Toggle date-time selector",
    cancel: "Cancel",
    set: "Set",
  },
  editor: {
    bold: "Bold",
    italic: "Italic",
    underline: "Underline",
    strikethrough: "Strikethrough",
    subscript: "Subscript",
    superscript: "Superscript",
    unlink: "Remove hyperlink",
    undo: "Undo",
    redo: "Redo",
    fontSize: "Font Size",
    fontName: "Font Name",
    format: "Format",
    alignLeft: "Align text left",
    alignRight: "Align text right",
    alignCenter: "Center text",
    alignJustify: "Justify",
    indent: "Indent",
    outdent: "Outdent",
    orderedList: "Insert ordered list",
    bulletList: "Insert unordered list",
    createTable: "Create a table",
    insertTableHint: "Create a {0} x {1} table",
    addRowBefore: "Add row above",
    addRowAfter: "Add row below",
    addColumnBefore: "Add column on the left",
    addColumnAfter: "Add column on the right",
    deleteRow: "Delete row",
    deleteColumn: "Delete column",
    deleteTable: "Delete table",
    mergeCells: "Merge cells",
    splitCell: "Split cell",
    hyperlink: "Insert hyperlink",
    "hyperlink-dialog-title": "Insert hyperlink",
    "hyperlink-dialog-content-address": "Web address",
    "hyperlink-dialog-content-title": "Title",
    "hyperlink-dialog-content-newwindow": "Open link in new window",
    "hyperlink-dialog-cancel": "Cancel",
    "hyperlink-dialog-insert": "Insert",
    image: "Insert image",
    "image-dialog-title": "Insert image",
    "image-address": "Web address",
    "image-title": "Title",
    "image-altText": "Alternate text",
    "image-width": "Width (px)",
    "image-height": "Height (px)",
    "image-cancel": "Cancel",
    "image-insert": "Insert",
    viewHtml: "View HTML",
    "viewHtml-dialog-title": "View HTML",
    "viewHtml-cancel": "Cancel",
    "viewHtml-update": "Update",
  },
  timepicker: {
    now: "NOW",
    selectNow: "Select Now",
    cancel: "Cancel",
    set: "Set",
    toggleTimeSelector: "Toggle TimeSelector",
    toggleClock: "Toggle Clock",
  },
  dropdowns: {
    nodata: "NO DATA FOUND.",
    clear: "Clear",
  },
  numerictextbox: {
    increment: "Increase value",
    decrement: "Decrease value",
  },
  upload: {
    cancel: "Cancel",
    clearSelectedFiles: "Clear",
    dropFilesHere: "Drop files here to upload",
    headerStatusUploaded: "Done",
    headerStatusUploading: "Uploading...",
    invalidFileExtension: "File type not allowed.",
    invalidFiles: "Invalid file(s). Please check file upload requirements.",
    invalidMaxFileSize: "File size too large.",
    invalidMinFileSize: "File size too small.",
    remove: "Remove",
    retry: "Retry",
    select: "Select files...",
    uploadSelectedFiles: "Upload",
    total: "Total",
    files: "files",
  },
  sortable: {
    noData: "No Data",
  },
  pager: {
    itemsPerPage: "items per page",
    info: "{0} - {1} of {2} items",
    firstPage: "Go to the first page",
    previousPage: "Go to the previous page",
    nextPage: "Go to the next page",
    lastPage: "Go to the last page",
    page: "Page",
    of: "of",
  },
  treelist: {
    filterClearButton: "Clear",
    filterEqOperator: "Is equal to",
    filterNotEqOperator: "Is not equal to",
    filterIsNullOperator: "Is null",
    filterIsNotNullOperator: "Is not null",
    filterIsEmptyOperator: "Is empty",
    filterIsNotEmptyOperator: "Is not empty",
    filterStartsWithOperator: "Starts with",
    filterContainsOperator: "Contains",
    filterNotContainsOperator: "Does not contain",
    filterEndsWithOperator: "Ends with",
    filterGteOperator: "Is greater than or equal to",
    filterGtOperator: "Is greater than",
    filterLteOperator: "Is less than or equal to",
    filterLtOperator: "Is less than",
    filterIsTrue: "Is true",
    filterIsFalse: "Is false",
    filterBooleanAll: "(All)",
    filterAfterOrEqualOperator: "Is after or equal to",
    filterAfterOperator: "Is after",
    filterBeforeOperator: "Is before",
    filterBeforeOrEqualOperator: "Is before or equal to",
    noRecords: "No records available",
  },
  scheduler: {
    editorValidationRequired: "Field is required.",
    editorValidationStart: "Start time must be be before End time.",
    editorValidationEnd: "End time must be after Start time.",
    allEvents: "All events",
    allDay: "all day",
    today: "Today",
    dayViewTitle: "Day",
    monthViewTitle: "Month",
    weekViewTitle: "Week",
    workWeekViewTitle: "Work Week",
    timelineViewTitle: "Timeline",
    agendaViewTitle: "Agenda",
    deleteTitle: "Delete",
    previousTitle: "Previous",
    nextTitle: "Next",
    showFullDay: "Show full day",
    showWorkDay: "Show business hours",
    editorOccurrence: "Edit current occurrence",
    editorSeries: "Edit the series",
    editorRecurringConfirmation:
      "Do you want to edit only this event occurrence or the whole series?",
    editorRecurringDialogTitle: "Edit Recurring Item",
    editorSave: "Save",
    editorCancel: "Cancel",
    editorDelete: "Delete",
    editorTitle: "Event",
    editorEventTitle: "Title",
    editorEventStart: "Start",
    editorEventStartTimeZone: "Start Time Zone",
    editorEventEnd: "End",
    editorEventEndTimeZone: "End Time Zone",
    editorEventAllDay: "All Day Event",
    editorEventDescription: "Description",
    editorEventSeparateTimeZones: "End in different Time Zone",
    editorEventTimeZone: "Specify Time Zone",
    recurrenceEditorRepeat: "Repeat",
    recurrenceEditorDailyInterval: "day(s)",
    recurrenceEditorDailyRepeatEvery: "Repeat every",
    recurrenceEditorWeeklyInterval: "week(s)",
    recurrenceEditorWeeklyRepeatEvery: "Repeat every",
    recurrenceEditorWeeklyRepeatOn: "Repeat on",
    recurrenceEditorMonthlyDay: "Day",
    recurrenceEditorMonthlyInterval: "month(s)",
    recurrenceEditorMonthlyRepeatEvery: "Repeat every",
    recurrenceEditorMonthlyRepeatOn: "Repeat on",
    recurrenceEditorYearlyOf: "of",
    recurrenceEditorYearlyRepeatEvery: "Repeat every",
    recurrenceEditorYearlyRepeatOn: "Repeat on",
    recurrenceEditorYearlyInterval: "year(s)",
    recurrenceEditorFrequenciesDaily: "Daily",
    recurrenceEditorFrequenciesMonthly: "Monthly",
    recurrenceEditorFrequenciesNever: "Never",
    recurrenceEditorFrequenciesWeekly: "Weekly",
    recurrenceEditorFrequenciesYearly: "Yearly",
    recurrenceEditorOffsetPositionsFirst: "First",
    recurrenceEditorOffsetPositionsSecond: "Second",
    recurrenceEditorOffsetPositionsThird: "Third",
    recurrenceEditorOffsetPositionsFourth: "Fourth",
    recurrenceEditorOffsetPositionsLast: "Last",
    recurrenceEditorWeekdaysDay: "Day",
    recurrenceEditorWeekdaysWeekday: "Weekday",
    recurrenceEditorWeekdaysWeekendday: "Weekend Day",
    recurrenceEditorEndAfter: "After",
    recurrenceEditorEndOccurrence: "occurrence(s)",
    recurrenceEditorEndLabel: "End",
    recurrenceEditorEndNever: "Never",
    recurrenceEditorEndOn: "On",
    deleteConfirmation: "Are you sure you want to delete this event?",
    deleteRecurringConfirmation:
      "Do you want to delete only this event occurrence or the whole series?",
    deleteOccurrence: "Delete current occurrence",
    deleteSeries: "Delete the series",
    deleteDialogTitle: "Delete Event",
    deleteRecurringDialogTitle: "Delete Recurring Item",
    dateTitle: "Date",
    timeTitle: "Time",
    eventTitle: "Event",
    noEvents: "no events",
  },
};
