import React, { useEffect, useState } from "react";
// import { toJS } from "mobx";
import { useHistory } from "react-router-dom";
import { Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { useStores } from "../stores";
import carePenguin from "src/services/carePenguin";
import { Loader } from "@progress/kendo-react-indicators";

const Caregivers = () => {
  // const { languageId, onLanguageChange, onProfileChange } =
  //   React.useContext(AppContext);
  const history = useHistory();
  const { login, ui } = useStores();
  const [caregiverError, setCaregiverError] = useState<string>("");
  const [revokeInviteError, setRevokeInviteError] = useState<string>("");
  const [sendInviteError, setSendInviteError] = useState<string>("");
  const [loadingCaregivers, setLoadingCaregivers] = useState<boolean>(false);
  const [copiedMessage, setCopiedMessage] = useState<string>("");
  const [removeCgVisible, setRemoveCgVisible] = useState<boolean>(false);
  const [removeCgName, setRemoveCgName] = useState<string>("");
  const [removeCgId, setRemoveCgId] = useState<number>(0);
  const [revokeCgVisible, setRevokeCgVisible] = useState<boolean>(false);
  const [revokeCgId, setRevokeCgId] = useState<number>(0);
  const [displayInviteForm, setDisplayInviteForm] = useState<boolean>(false);
  const [isAccountOwner, setIsAccountOwner] = useState<boolean>(false);
  const [inviteePhoneNumber, setInviteePhoneNumber] = useState<string>("");
  const [inviteeCountry, setInviteeCountry] = useState<string>("US");

  const doFetchCaregivers = async () => {
    const call: any = await carePenguin.getMembershipData(
      (ui.account as Account).accountId
    );
    if (call.result) {
      ui.setMemberList(call.result.members);
      ui.setInvitesList(call.result.invites);
    }
    if (call.result?.errors) {
      console.log("[ProfileScreen]", "get caregivers Error");
      setCaregiverError(call.result.errors.join("\n"));
      return;
    }
    setLoadingCaregivers(false);
  };

  useEffect(() => {
    if (!login.loginObject) {
      console.log("[not logged in");
      carePenguin.doLogout();
      return;
    }
    const userId = login.loginObject?.user?.id;
    const accountHolderId = ui.account?.accountOwnerUserId;
    // const isPremium =
    //   (ui.account as Account).accountBillingOverview === "PAID" &&
    //   (ui.account as Account).accountId !== 23;
    // ui.setIsPremiumAccount(isPremium);
    if (userId === accountHolderId) {
      setIsAccountOwner(true);
    } else {
      // TODO: make sure this is set to false if it was true for testing
      setIsAccountOwner(false);
    }
    setCaregiverError("");
    setCopiedMessage("");
    setRemoveCgId(0);
    setRemoveCgName("");
    setRemoveCgVisible(false);
    setLoadingCaregivers(true);
    doFetchCaregivers();
    // eslint-disable-next-line
  }, []);

  const removeCaregiver = async () => {
    const accountId = (ui.account as Account).accountId;
    const call: any = await carePenguin.removeUser(accountId, removeCgId);
    if (call.result.errors) {
      console.log(
        "[ProfileScreen]",
        "remove caregver Error",
        call.result.errors
      );
      setRevokeInviteError(call.result.errors.join("\n"));
      setRemoveCgVisible(false);
      return;
    }
    setRemoveCgVisible(false);
    history.go(0);
    return await doFetchCaregivers();
  };

  const revokeInvitation = async () => {
    const accountId = (ui.account as Account).accountId;
    const call: any = await carePenguin.revokeInvitation(accountId, revokeCgId);
    if (call.result.errors) {
      console.log("[ProfileScreen]", "revoke invite Error", call.result.errors);
      setRevokeInviteError(call.result.errors.join("\n"));
      setRevokeCgVisible(false);
      return;
    }
    setRevokeCgVisible(false);
    history.go(0);
    return await doFetchCaregivers();
  };

  const renderMemberList = () => {
    if (!ui.memberList) {
      return;
    }
    const handleCopyPhoneClick = (number: string) => {
      navigator.clipboard.writeText(number);
      setCopiedMessage("Phone number copied to clipboard.");
      setTimeout(() => {
        setCopiedMessage("");
      }, 3000);
    };

    const renderRemoveCgAlert = (name: string, id: number) => {
      setRemoveCgVisible(true);
      setRemoveCgName(name);
      setRemoveCgId(id);
    };

    return ui.memberList.map((member: Member) => {
      if (member.email === ui.account?.accountOwnerEmail) {
        return (
          <div key={member.email} className="caregiver-card-container">
            <div>
              <p className="caregiver-text">{member.name}</p>
              <p className="caregiver-text">{member.phoneNumber}</p>
            </div>
            <div className="caregiver-actions">
              <Button
                className="remove-cg-btn"
                style={{ display: "none" }}
                disabled
              >
                <p className="remove-caregiver-text">remove</p>
              </Button>
              <p style={{ display: "none" }}>|</p>
              <Button
                className="copy-cg-btn"
                onClick={() => handleCopyPhoneClick(member.phoneNumber)}
              >
                <p className="copy-caregiver-text">copy</p>
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div key={member.email} className="caregiver-card-container">
          <div>
            <p className="caregiver-text">{member.name}</p>
            <p className="caregiver-text">{member.phoneNumber}</p>
          </div>
          <div className="caregiver-actions">
            <Button
              className="remove-cg-btn"
              onClick={() => renderRemoveCgAlert(member.name, member.id)}
              style={isAccountOwner ? {} : { display: "none" }}
            >
              <p className="remove-caregiver-text">remove</p>
            </Button>
            <p
              style={
                isAccountOwner
                  ? { fontWeight: 700, color: "#12384C" }
                  : { display: "none" }
              }
            >
              |
            </p>
            <Button
              className="copy-cg-btn"
              onClick={() => handleCopyPhoneClick(member.phoneNumber)}
            >
              <p className="copy-caregiver-text">copy</p>
            </Button>
          </div>
        </div>
      );
    });
  };

  const renderInvitations = () => {
    if (!ui.invitesList) {
      return;
    }
    const handleCopyPhoneClick = (number: string) => {
      navigator.clipboard.writeText(number);
      setCopiedMessage("Phone number copied to clipboard.");
      setTimeout(() => {
        setCopiedMessage("");
      }, 3000);
    };

    const renderRevokeCgAlert = (id: number) => {
      setRevokeCgVisible(true);
      setRevokeCgId(id);
    };

    return ui.invitesList.map((invite: Invite) => {
      return (
        <div key={invite.id} className="caregiver-card-container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p className="caregiver-text">{invite.phoneNumber}</p>
            {invite.expired ? (
              <p className="expired-text">(Invite Expired)</p>
            ) : null}
          </div>
          <div className="caregiver-actions">
            <Button
              className="remove-cg-btn"
              onClick={() => renderRevokeCgAlert(invite.id)}
              style={isAccountOwner ? {} : { display: "none" }}
            >
              <p className="remove-caregiver-text">revoke</p>
            </Button>
            <p
              style={
                isAccountOwner
                  ? { fontWeight: 700, color: "#12384C" }
                  : { display: "none" }
              }
            >
              |
            </p>
            <Button className="copy-cg-btn">
              <p
                className="copy-caregiver-text"
                onClick={() => handleCopyPhoneClick(invite.phoneNumber)}
              >
                copy
              </p>
            </Button>
          </div>
        </div>
      );
    });
  };

  const handleSendInvitation = async (
    phoneNumber: string,
    countryCode: string
  ) => {
    const accountId = (ui.account as Account).accountId;
    const call: any = await carePenguin.sendInvitation(
      accountId,
      phoneNumber,
      countryCode
    );
    if (call.result.errors) {
      setSendInviteError(call.result.errors.join("\n"));
      return;
    }
    setDisplayInviteForm(false);
    setInviteeCountry("US");
    setInviteePhoneNumber("");
    // return await doFetchCaregivers();
    history.go(0);
  };

  if (ui.isGettingAccount) {
    return (
      <div
        id="Profile"
        className="profile-page main-content"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader style={{ marginTop: "30px" }} type="infinite-spinner" />
      </div>
    );
  }
  return (
    <div id="Profile" className="profile-page main-content">
      {removeCgVisible && (
        <Dialog
          title={"Confirm Removal"}
          onClose={() => setRemoveCgVisible(false)}
          closeIcon={false}
          style={{ fontFamily: "Rubik-Medium" }}
        >
          <p
            style={{
              // margin: "25px",
              fontFamily: "Rubik-Regular",
              textAlign: "center",
            }}
          >
            Are you sure you want to remove {removeCgName} as a Caregiver? You
            can always re-invite them.
          </p>
          <DialogActionsBar>
            <button
              className="k-button dialogue-cancel-btn"
              onClick={() => setRemoveCgVisible(false)}
            >
              No
            </button>
            <button
              className="k-button dialogue-confirm-btn"
              onClick={() => removeCaregiver()}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {revokeCgVisible && (
        <Dialog
          title={"Confirm Revocation"}
          onClose={() => setRevokeCgVisible(false)}
          closeIcon={false}
          style={{ fontFamily: "Rubik-Medium" }}
        >
          <p
            style={{
              // margin: "25px",
              textAlign: "center",
              fontFamily: "Rubik-Regular",
            }}
          >
            Are you sure you want to revoke this invitation? You can always
            re-invite them.
          </p>
          <DialogActionsBar>
            <button
              className="k-button dialogue-cancel-btn"
              onClick={() => setRevokeCgVisible(false)}
            >
              No
            </button>
            <button
              className="k-button dialogue-confirm-btn"
              onClick={() => revokeInvitation()}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      <div className="card-container">
        <div className="caregiver-header">
          <h3 className="caregiver-title">Caregivers</h3>
        </div>
        {!ui.isPremiumAccount ? (
          <div className="cg-unavailable-container">
            <div>
              <h3>
                Share with your loved ones! Available on CarePenguin Premium!
              </h3>
              <p>
                Invite family members or other caregivers to your CarePenguin
                account to share the responsibility of caring for a loved one.
              </p>
              <a href="https://carepenguin.com/premium" target="blank">
                Click here to sign up for CarePenguin Premium
              </a>
            </div>
          </div>
        ) : (
          <div>
            {caregiverError && <Error>{caregiverError}</Error>}
            {revokeInviteError && <Error>{revokeInviteError}</Error>}
            {copiedMessage ? (
              <p className="copied-msg">{copiedMessage}</p>
            ) : null}
            <div>
              {/* // TODO: make real loading indicator */}
              {loadingCaregivers && ui.memberList ? (
                <p>Loading...</p>
              ) : (
                renderMemberList()
              )}
            </div>
            <div className="invites-header">
              <h3 className="caregiver-title">Open Invitations</h3>
              <Button
                style={
                  isAccountOwner
                    ? { fontFamily: "Rubik-Medium" }
                    : { display: "none" }
                }
                className="invite-cg-btn"
                onClick={() => setDisplayInviteForm(!displayInviteForm)}
              >
                {!displayInviteForm ? "Invite New Caregiver" : "Cancel"}
              </Button>
            </div>
            {sendInviteError && <Error>{sendInviteError}</Error>}
            {!displayInviteForm ? null : (
              <div className="invite-container">
                <p className="invite-text">
                  Enter the mobile number of the caregiver you would like to
                  invite to your account.
                </p>
                <p className="invite-text">
                  The recipient will get a text message that you have invited
                  them into your account on Carepenguin.
                </p>
                <p className="invite-text">
                  The new caregiver will receive instructions to get the
                  CarePenguin Mobile App from the App Store, start the app, and
                  enter the provided invite code that will link them to your
                  account.
                </p>
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="US"
                  value={inviteePhoneNumber}
                  onChange={(val: string) => {
                    if (!val) {
                      return;
                    }
                    const parsedPhoneData = parsePhoneNumber(val);
                    const country = parsedPhoneData?.country;
                    const phone: any = parsedPhoneData?.number;
                    setInviteeCountry(country || "US");
                    setInviteePhoneNumber(phone);
                  }}
                />
                <Button
                  onClick={() =>
                    handleSendInvitation(inviteePhoneNumber, inviteeCountry)
                  }
                  className="do-invite-cg-btn"
                  disabled={!inviteePhoneNumber}
                >
                  Send Invite
                </Button>
              </div>
            )}
            {/* // TODO: make real loading indicator */}
            {loadingCaregivers && ui.invitesList ? (
              <p>Loading...</p>
            ) : (
              renderInvitations()
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Caregivers;
