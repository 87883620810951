import React, { useEffect, useState } from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import { requiredValidator } from "../validators";

import { AppContext } from "../AppContext";
import { useStores } from "src/stores";
import { useServices } from "src/services";
import { Input } from "src/components/form/Input";
import cpHeaderLogo from "../assets/images/CarePenguin_header_logo.png";

const ForgotPasswordCode = () => {
  const { ...formValues } = React.useContext(AppContext);
  const localizationService = useLocalization();
  const history = useHistory();

  const [signUpError, setSignUpError] = useState<string>();
  const { login, ui } = useStores();
  const { carePenguin } = useServices();

  useEffect(() => {
    if (login.loginObject) {
      console.log("[LoginScreen]", "I think we are already logged in");
      carePenguin.getAccount().then(() => {
        history.replace("/");
      });
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = React.useCallback(
    async (dataItem) => {
      const call: any = await carePenguin.doResetPassword(
        ui.resetPasswordEmail,
        dataItem.code,
        ui.resetPasswordPass
      );

      if (call.result.errors) {
        console.log("[ForgotPasswordCode]", "sign up verify Error");
        setSignUpError(call.result.errors.join("\n"));
        return;
      }
      if (call.result.user) {
        console.log("[ForgotPasswordCode]", "success");
        login.setLoginObject(call.result as Login);
        await carePenguin.getAccount();
      }
      history.replace("/login");
    },
    [carePenguin, ui.resetPasswordEmail, ui.resetPasswordPass, history, login]
  );

  return (
    <div id="Login" className="login-page">
      <div className="card-container" style={{ maxWidth: 700 }}>
        <div className="card-component">
          <div className="header-img-container">
            <img
              src={cpHeaderLogo}
              alt="carepenguin header logo"
              className="cp-header-img"
            />
          </div>
          <Button
            onClick={() => history.push("/forgotPassword")}
            className="back-btn"
          >
            <span className="k-icon k-i-arrow-chevron-left"></span>Back
          </Button>
          <p className="reset-text">
            Check your texts for the code sent to you and enter it below. You
            will then be redirected to the login page. If you did not receive a
            text, please try again or contact support.
          </p>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              ...formValues,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                {signUpError && <Error>{signUpError}</Error>}
                <Field
                  id={"code"}
                  name={"code"}
                  label={localizationService.toLanguageString(
                    "custom.verificationCode",
                    "Verification Code"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <hr />
                <div className={"k-form-buttons"}>
                  <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {localizationService.toLanguageString(
                      "custom.submit",
                      "Submit"
                    )}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordCode;
