const emailRegex = new RegExp(
  '^(([^<>()\\[\\]\\\\.,;:`\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:`\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.(?!con))+[a-zA-Z]{2,}))$'
);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const htmlRegex = new RegExp(/<[^>]*>/g);

export const emailValidator = (value) =>
  emailRegex.test(value) ? "" : "Please enter a valid email address.";
export const requiredValidator = (value) =>
  value ? "" : "This field is required";
export const phoneValidator = (value) =>
  value && phoneRegex.test(value) ? "" : "Please enter a valid phone number.";
export const biographyValidator = (value) =>
  value && value.replace(htmlRegex, "").length >= 50
    ? ""
    : "Biography must be at least 50 characters long.";
// export const confirmPassValidator = (value, password) => {
//   console.log("VALUE", value, password);
//   return value && value === password ? "" : "Passwords must match.";
// };
