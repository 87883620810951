/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { useStores } from "src/stores";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import carePenguin from "src/services/carePenguin";
import cpLogo from "../assets/images/CarePenguin_logo.png";

const Header = (props) => {
  const { onButtonClick } = props;
  const [selectedAccount, setSelectedAccount] = useState({});
  const [accountList, setAccountList] = useState([]);
  const [isAccountOwner, setIsAccountOwner] = useState(false);
  const [notOwnerError, setNotOwnerError] = useState("");
  const [chargbeeError, setChargebeeError] = useState("");

  const { login, ui } = useStores();
  const history = useHistory();

  useEffect(() => {
    if (!login.loginObject) {
      console.log('[not logged in')
      carePenguin.doLogout();
      return;
    }
    const userId = login.loginObject?.user?.id;
    const accountHolderId = ui.account?.accountOwnerUserId;
    // const isPremium =
    //   (ui.account as Account).accountBillingOverview === "PAID" &&
    //   (ui.account as Account).accountId !== 23;
    // ui.setIsPremiumAccount(isPremium);
    if (userId === accountHolderId) {
      setIsAccountOwner(true);
    } else {
      setIsAccountOwner(false);
    }
    console.log('HEADER', login.selectedAccountID);
    let localAccount;
    // we simplify the account objects because DropDownList wants the full defaultValue regardless of what the dataItemKey is
    const localAccountList = login.loginObject?.accounts.map((account) => {
      if (account.id.toString() === login.selectedAccountID.toString()) {
        localAccount = { name: account.name, id: `${account.id}` };
      }
      return { name: account.name, id: `${account.id}` };
    });
    setSelectedAccount(localAccount)
    setAccountList(localAccountList)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login.loginObject, login.loginObject?.accounts, login.selectedAccountID]);


  return (
    // <header className="header" style={{ backgroundImage: `url(${headerBg})` }}>
    <header className="header">
      <div className="nav-container">
        <div className="menu-button">
          <span className={"k-icon k-i-menu"} onClick={onButtonClick} />
        </div>

        <div className="title" onClick={() => history.push('/')} style={{ cursor: 'pointer'}}>
          <img src={cpLogo} alt="carePenguin logo" style={{ width: 50 }} />
        <div className="settings">
          {login.loginObject && (
            <DropDownList
              className="dropdown"
              data={accountList}
              textField="name"
              dataItemKey="id"
              defaultValue={selectedAccount}
              value={selectedAccount}
              onChange={async (event) => {
                console.log("changing accounts...", event.target.value.id);
                setSelectedAccount({ name: event.target.value.name, id: `${event.target.value.id}` })
                await carePenguin.getAccount(event.target.value.id);
                history.push('/locations')
              }}
            />
          )}
          &nbsp;
        </div>
        </div>
        {notOwnerError ? 
        <p style={{ color: '#EF3D3F', fontFamily: 'Rubik-Regular', marginRight: 10}}>{notOwnerError}</p>
          : null
        }
        {chargbeeError ? 
        <p style={{ color: '#EF3D3F', fontFamily: 'Rubik-Regular', marginRight: 10}}>{chargbeeError}</p>
          : null
        }
        <div className="header-buttons">
          <Button
            onClick={() => {
              const cbInstance = window.Chargebee.getInstance();
              const cart = cbInstance.getCart();
              const product = cbInstance.initializeProduct(
                "cp-in-house-activity-sensor",
                1
              );
              cart.replaceProduct(product);
              // TODO: what if user is a caregiver only?
              const customer = {
                email: login.loginObject.user.email.includes('+') ? null : login.loginObject.user.email,
                carePenguinUserId: login.loginObject.user.id,
              }
              cart.setCustomer(customer);
              cart.proceedToCheckout();
            }}
            className="buy-sensor-btn"
          >
            Buy Sensor
          </Button>
          {/* <Button
            onClick={async () => {
              if (!isAccountOwner) {
                setNotOwnerError("Only account owners can access this feature.");
                setTimeout(() => {
                  setNotOwnerError('');
                }, 6000)
                return;
              }
              const call = await carePenguin.createPortalSession(
                ui.account.accountId
              );
              if (call.errors) {
                setChargebeeError(call.errors.join("\n"));
                return;
              }
              let chargebeeInstance;
              chargebeeInstance = (window).Chargebee.init({
                site: "carepenguin",
              });
              chargebeeInstance = (window).Chargebee.getInstance();
              chargebeeInstance.setPortalSession(call)
              let cbPortal = chargebeeInstance.createChargebeePortal();
              cbPortal.open();
            }}
            className="manage-sub-btn"
          >
            Manage Subscription
          </Button> */}
        </div>
      </div>
    </header>
  );
};

Header.displayName = "Header";
Header.propTypes = {
  page: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default Header;
