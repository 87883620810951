import * as React from 'react';
import LoginStore from './loginStore';
import UIStore from './uiStore';
import SignUpStore from './signUpStore';

type StoreType = {
  [index: string]: IHydratedStore;
  'login': typeof LoginStore;
  'ui': typeof UIStore;
  'signUp': typeof SignUpStore;
};

export const stores:StoreType = {
  login: LoginStore,
  ui: UIStore,
  signUp: SignUpStore,
};

const storeContext = React.createContext(stores);

export const withStoresProvider = (C: React.FC) => (props: object) => {
  return (
    <storeContext.Provider value={stores}>
      <C {...props} />
    </storeContext.Provider>
  );
};

export const useStores = () => React.useContext(storeContext);

export const hydrateStores = async () => {
  console.log('[hydrateStores]');
  for (const key in stores) {
    if (Object.prototype.hasOwnProperty.call(stores, key)) {
      console.log('[hydrateStores]', key);
      const s = stores[key];

      if (s.hydrate) {
        console.log('[stores]','hydrating',key);
        await s.hydrate();
      }
    }
  }
};
